import { Field, Step } from "@lib/shared/types";
import { Dispatch, FormEvent } from "react";

export enum ActionTypes {
    SHOW_EXIT_MODAL = "SHOW_EXIT_MODAL",
    SET_STEP = "SET_STEP",
    SET_FIELDS_STATE = "SET_FIELDS_STATE",
    SET_LOADING_FORM = "SET_LOADING_FORM",
    SET_FORM_SUBMITTED = "SET_FORM_SUBMITTED",
    SET_FORM_ERROR_FIELD = "SET_FORM_ERROR_FIELD",
    SET_FORM_ERROR = "SET_FORM_ERROR",
    SET_NEXT_STEP = "SET_NEXT_STEP",
    SET_OTP = "SET_OTP",
    SET_FORM_WAITING_OTP = "SET_FORM_WAITING_OTP",
    SET_STEP_STATUS = "SET_STEP_STATUS",
    RESET_FORM_SUBMIT = "RESET_FORM_SUBMIT",
    SHOW_REGULATIONS_PAGE = "SHOW_REGULATIONS_PAGE",
    SHOW_CUSTOM_LISTING_MODAL = "SHOW_CUSTOM_LISTING_MODAL",
    SET_FORM_MODAL = "SET_FORM_MODAL",
    TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL = "TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL",
    SET_OPERATION = "SET_OPERATION",
    SET_GLOBAL_LOADING = "SET_GLOBAL_LOADING",
}

export interface Buyer {
    buyerId: string | number;
    buyerName: string;
    displayName: string;
    logo: string;
    selected: boolean;
    isNoble?: boolean;
}
export interface InitState {
    waitingOtp: boolean;
    submitted: boolean;
    loadingForm: boolean;
    currentStep: number;
    visitedSteps: { [x: number]: number };
    fieldsState: FieldsData;
    formError: string | null;
    formErrors: number;
    formErrorsResolved: boolean;
    otp: {
        isRequired: boolean;
        isResolved: boolean;
        countDown: number;
    };
    showExitModal: boolean;
    stepStatus: "success" | "error";
    blockBack: boolean;
    showRegulations: boolean;
    showCustomListingModal: boolean;
    showFormModal: boolean;
    showFromInExitModal: boolean;

    // 1 to 1 consent
    operation: Operation | null;
    globalLoading: boolean;
}

export interface Operation {
    operationId?: string;
    key?: "FETCH_BUYERS" | "ROUND_ONE" | "ROUND_TWO";
    loadingBuyers?: boolean;
    secondRoundBuyers?: Buyer[];
    firstRoundBuyers?: Buyer[];
    winnerBuyer?: Buyer[] | null | undefined;
    hasDomainConsent?: boolean;
}

export type FormReducerAction =
    | {
          type: ActionTypes.SET_FORM_ERROR;
          payload: string | null;
      }
    | {
          type: ActionTypes.SET_FORM_SUBMITTED;
          payload: { formId: number; isOtpResolved?: boolean };
      }
    | { type: ActionTypes.SET_STEP; payload: number }
    | { type: ActionTypes.SHOW_EXIT_MODAL; payload: number }
    | { type: ActionTypes.SET_FORM_ERROR; payload: string | null }
    | {
          type: ActionTypes.SET_FIELDS_STATE;
          payload: FieldsData | null;
      }
    | { type: ActionTypes.SET_LOADING_FORM; payload: boolean }
    | {
          type: ActionTypes.SET_FORM_ERROR_FIELD;
          payload: {
              //   step: number;
              formErrors: number;
              fields: FieldsData;
          };
      }
    | { type: ActionTypes.SET_NEXT_STEP; payload: undefined }
    | {
          type: ActionTypes.SET_OTP;
          payload: {
              isRequired: true;
              isResolved: boolean;
              countDown: number;
          };
      }
    | { type: ActionTypes.SET_FORM_WAITING_OTP; payload: boolean }
    | {
          type: ActionTypes.SET_STEP_STATUS;
          payload: boolean;
      }
    | { type: ActionTypes.RESET_FORM_SUBMIT }
    | { type: ActionTypes.SET_FORM_MODAL; payload: boolean }
    | {
          type: ActionTypes.TOGGLE_SHOW_FORM_INSIDE_EXIT_MODAL;
          payload: boolean | undefined;
      }
    | {
          type: ActionTypes.SHOW_REGULATIONS_PAGE;
          payload: {
              showRegulations: boolean;
          };
      }
    | {
          type: ActionTypes.SHOW_CUSTOM_LISTING_MODAL;
          payload: {
              showCustomListingModal: boolean;
          };
      }
    | {
          type: ActionTypes.SET_OPERATION;
          payload: Partial<Operation> & {
              isOtpResolved?: boolean;
              formErrors?: number;
          };
      }
    | {
          type: ActionTypes.SET_GLOBAL_LOADING;
          payload: boolean;
      };
// | {
//       type: ActionTypes.SET_LOADING_BUYERS;
//       payload: boolean;
//   }
// | {
//       type: ActionTypes.SET_AVAILABLE_BUYERS;
//       payload: Buyer[];
//   }
// | {
//       type: ActionTypes.SET_SELECTED_BUYERS;
//       payload: Buyer[];
//   }
// | {
//       type: ActionTypes.SET_START_SECOND_ROUND_BIDDING;
//       payload: boolean;
//   }
// | {
//       type: ActionTypes.SET_FIRST_ROUND_BUYER;
//       payload: Buyer;
//   };

export interface FormOtp {
    isRequired: boolean;
    isResolved: boolean;
    handleSubmit: (otp: string) => Promise<{ success: boolean }>;
    handleResend: () => Promise<{ success: boolean }>;
    handleScrollFormTop: () => void;
    handleEditPhoneNumber: (
        phoneNumber: string,
    ) => Promise<{ success: boolean }>;
    countDownTime: number;
}
export interface StreetAddress {
    streetNumber?: string;
    route?: string;
    toZipCode?: string;
    toCountry?: string;
    toCity?: string;
    toState?: string;
}
export interface ForceShowOffers {
    codeName: string;
    value: string;
    listSlug: string;
}
export interface FieldData {
    valid: boolean;
    errorMessage: string | null;
    value: string;
    optionSelectedIndex?: string | undefined;
    changed: boolean;
    fieldType: string;
    defaultValues: { options: { label: string; value: string }[] } | null;
}

export interface FieldsData {
    [x: string]: FieldData;
}

export interface ITextBlock {
    id: number;
    text: string;
    offerIds: number | null;
    categoryId: number;
    domainId: number;
    queryString: string | null;
    isActive: boolean;
    position: string;
    translations: [];
}
export interface UseFormHook {
    handleFieldChange: (
        field: Pick<Field, "mask" | "codeName" | "fieldType">,
        val: string,
    ) => void;

    handleFieldBlur: (field: Field) => void;
    handlePrevStep: () => void;
    handleSubmit: (
        ev: FormEvent<HTMLFormElement>,
        options?: { skipValidation?: boolean; isSecondForm?: boolean },
    ) => void;
    handleOutsideFormSubmit: () => void;
    currentStep: number;
    steps: Step[];
    visitedSteps: {};
    fieldsData: FieldsData;
    isLoading: boolean;
    successMessage: string;
    jornayaIdElement: JSX.Element;
    formError: null | string;
    formId: number;
    otp: FormOtp;
    formSubmitted: boolean;
    showExitModal: boolean;
    stepStatus: string;
    formErrors: number;
    formErrorsResolved: boolean;
    checkIfStepHasOneField: (fieldsData: FieldsData) => boolean;
    showRegulations: boolean;
    showCustomListingModal: boolean;
    setLoading: (isLoading: boolean) => void;
    setHasRegulations: (hasRegulations: boolean) => void;
    setShowCustomListingModal: (show: boolean) => void;
    textBlocks: ITextBlock[];
    showPiiCompletionOtherFields: boolean;
    setShowPiiCompletionOtherFields: (show: boolean) => void;
    showFormModal: boolean;
    toggleFormModal: (payload: boolean) => void;
    listingSlug: string | null;
    toggleShowFormInExitModal: (payload?: boolean) => void;
    handleCloseFormInsideExitModal: () => void;
    showFromInExitModal: boolean;
    operation: Operation | null;
    // loadingBuyers: boolean;
    // availableBuyers: Buyer[];
    // selectedBuyers: Buyer[];
    dispatch: Dispatch<FormReducerAction>;
    // secondRoundBidding: boolean;
    // firstRoundBuyer: Buyer | null;
    startSecondRoundBidding: ({
        selectedBuyers,
    }: {
        selectedBuyers: Buyer[];
    }) => Promise<void>;
    globalLoading: boolean;
}
