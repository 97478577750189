import React from "react";

const FormSkeleton = () => {
    return (
        <div className="flex flex-col gap-8 w-[300px] md:w-full">
            <div className="flex flex-col gap-2">
                <div className="skeleton skeleton-text !w-[200px] !mb-8" />
                <div className="skeleton skeleton-text" />
                <div className="skeleton skeleton-text" />
                <div className="skeleton skeleton-text" />
                <div className="skeleton skeleton-text" />
            </div>
            <div className="flex items-start gap-2 justify-between">
                <div className="flex flex-col !w-[250px]">
                    <div className="skeleton skeleton-text !w-[50px]" />
                    <div className="skeleton skeleton-input" />
                </div>
                <div className="flex flex-col !w-[250px]">
                    <div className="skeleton skeleton-text !w-[50px]" />
                    <div className="skeleton skeleton-input" />
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="skeleton skeleton-button" />
                <div className="flex flex-col gap-1">
                    <div className="skeleton skeleton-text" />
                    <div className="skeleton skeleton-text" />
                    <div className="skeleton skeleton-text" />
                    <div className="skeleton skeleton-text" />
                    <div className="skeleton skeleton-text" />
                    <div className="skeleton skeleton-text" />
                </div>
            </div>
        </div>
    );
};

export default FormSkeleton;
